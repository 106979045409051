<template>
    <h1>404: Page not found</h1>
</template>

<script>
export default {

}
</script>

<style scoped>
    h1 {
        text-align: center;
    }
</style>